.login-icon {
  width: 25px;
}

$logocolor1: #285f8c;
$logocolor2: #00c89f;
// .logo-block{

// }
.notification-block {
  // width: 80%;
  text-align: right;
}

.mat-drawer-container {
  background: none !important;
}

.spacer-between {
  flex: 1 1 auto;
}

.navbar-toggler-icon {
  height: 23px;
  background-image: url(data:image/svg+xml;charset=utf8;base64,PHN2ZyB2aWV3Qm94PScwIDAgMzAgMzAnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggc3Ryb2tlPScjNzM4MThmJyBzdHJva2Utd2lkdGg9JzIuMjUnIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLW1pdGVybGltaXQ9JzEwJyBkPSdNNCA3aDIyTTQgMTVoMjJNNCAyM2gyMicvPjwvc3ZnPg==);
}

.hide-menu {
  max-width: 0 !important;
  min-width: 0 !important;
}

.default-menu {
  max-width: 60px !important;
  -webkit-transition: max-width 0.5s ease;
  transition: max-width 0.5s ease;
}

.menu {
  -webkit-transition: max-width 0.5s ease;
  transition: max-width 0.5s ease;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
}

.mat-mdc-table .mat-mdc-header-row {
  background-color: $logocolor2;
  height: 36px !important;
}

.mat-mdc-table .mat-mdc-header-row .mat-mdc-header-cell {
  color: white;
}

.mat-mdc-row {
  height: 34px !important;
}

.page-header-text {
  color: $logocolor1;
  font-size: 20px;
}

.page-header-text-bg {
  color: #ffffff;
  font-size: 20px;
  background-color: #445e75;
  width: 100%;
  height: 30px;
  display: block;
}

.page-sub-header-text {
  color: $logocolor1;
  font-size: 14px;
}

.white-bg {
  background-color: #ffffff;
}

.mat-divider {
  color: #e6e9ed;
  display: block;
  margin: 0;
  /*border-top: 9px solid #e6e9ed;
  border-top-style: solid;*/
}

.mat-paginator-container {
  justify-content: center !important;
  max-height: 30px !important;
}

.page-padding {
  padding-top: 8px;
}

.mat-expansion-panel-header-description,
.mat-expansion-panel-header-title {
  margin-right: 0px !important;
}

.mat-mdc-form-field {
  width: 100%;
}

.case-id {
  color: $logocolor1;
  font-weight: bold;
}

.profile-number {
  font-weight: bold;
}


.dashboard-content {
  background-color: #eff3f8 !important;
  border: none !important;
}

.info-box {
  display: block;
  min-height: 70px;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 15px;
}

.bg-aqua,
.callout.callout-info,
.alert-info,
.label-info,
.modal-info .modal-body {
  background-color: #00c0ef !important;
}

.info-box-content {
  padding: 10px 6px;
  margin-left: 70px;
}

.info-box-text {
  text-transform: uppercase;
}

.progress-description,
.info-box-text {
  display: block;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-box-number {
  padding-top: 9px;
  display: block;
  font-weight: bold;
  font-size: 14px;
}

.info-box small {
  font-size: 14px;
}

.dashboard-icon.info-box-icon {
  border-top-left-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 2px;
  display: block;
  float: left;
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 25px;
  line-height: 50px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}


.list-info-box {
  display: block;
  min-height: 40px;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 1px;
  margin-left: 1px;
}

.bg-aqua,
.callout.callout-info,
.alert-info,
.label-info,
.modal-info .modal-body {
  background-color: #00c0ef !important;
}

.list-info-box-content {
  padding: 10px 6px;
}

.list-info-box-text {
  text-transform: uppercase;
}

.list-info-box-text {
  display: block;
  font-size: 13px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
}

.list-info-box-number {
  padding-top: 9px;
  display: block;
  font-weight: bold;
  font-size: 14px;
}

.list-info-box small {
  font-size: 14px;
}

.list-info-box-icon {
  display: block;
  float: left;
  height: 27px;
  width: 27px;
  text-align: center;
  font-size: 15px;
  line-height: 27px;
  background: #0003;
  border-radius: 50%;
  margin-left: 0px;
  margin-top: 0px;
}

.dashboard-icon i {
  color: white;
}

.bg-orange {
  background-color: orange !important;
}

.bg-red {
  background-color: #FFDCDE;

  td {
    color: #CE1518;
  }
}

.bg-green {
  background-color: green;
}

.bg-darkorange {
  background-color: darkorange !important;
}

.bg-lightgreen {
  background-color: lightgreen !important;
}

.bg-brown {
  background-color: #a52a2a !important;
}

.dashboard-content-body .small-box-footer {
  float: right !important;
  font-size: 12px !important;
  margin-top: 11px !important;
}

#dashboard-css {
  .mat-mdc-card-header {
    background-color: #eff3f8 !important;
    border: none !important;
    padding-top: 30px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 3px solid #d2d6de;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  }

  .box.box-info {
    border-top-color: #444d58 !important;
  }

  .box-header>.fa,
  .box-header>.glyphicon,
  .box-header>.ion,
  .box-header .box-title {
    display: inline-block;
    font-size: 18px;
    margin: 0;
    line-height: 1;
    padding: 12px 15px;
  }

  .box-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    padding: 10px;
  }

  .table-responsive {
    min-height: .01%;
    overflow-x: auto;
  }

  .no-margin {
    margin: 0 !important;
  }
}


.final-rpt-text {
  width: 60%;
  color: #fff;

  border-radius: 5px;
  display: inline-table;
  text-align: center;

}

.mat-dialog-container {
  max-height: 95vh !important;
}